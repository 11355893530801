var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Buttons',{attrs:{"textSave":_vm.$t('GENERAL.NEW'),"save_loading":_vm.save_loading,"btnSaveNew":false,"btnDuplicate":false,"btnDelete":false,"btnCancel":false},on:{"save":_vm.newTemplate}}),_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-body pt-2"},[_c('v-tabs',[_c('v-tab',{on:{"click":_vm.setActiveTab}},[_vm._v(_vm._s(_vm.$t("PRICING.TAB1")))]),_c('v-tab',{on:{"click":_vm.setActiveTab}},[_vm._v(_vm._s(_vm.$t("PRICING.TAB3")))]),_c('v-tab-item',[_c('div',{staticClass:"form-group row"},[_c('v-card',{staticClass:"w-100"},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('GENERAL.SEARCH'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsActive,"search":_vm.search},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                      name: 'pricing-edit',
                      params: { id: item.productPricingTemplateId },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at,_vm.campus.date_format))+" ")]}},{key:`item.ruleTypeThresholdDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.ruleTypeThresholdDate,_vm.campus.date_format))+" ")]}}],null,true)})],1)],1)]),_c('v-tab-item',[_c('div',{staticClass:"form-group row"},[_c('v-card',{staticClass:"w-100"},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('GENERAL.SEARCH'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsExpired,"search":_vm.search},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                      name: 'pricing-edit',
                      params: { id: item.productPricingTemplateId },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at,_vm.campus.date_format))+" ")]}},{key:`item.ruleTypeThresholdDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.ruleTypeThresholdDate,_vm.campus.date_format))+" ")]}}],null,true)})],1)],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }