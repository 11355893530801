<template>
  <!--begin::Pricing-->
  <div>
    <Buttons
      :textSave="$t('GENERAL.NEW')"
      v-on:save="newTemplate"
      :save_loading="save_loading"
      :btnSaveNew="false"
      :btnDuplicate="false"
      :btnDelete="false"
      :btnCancel="false"
    />
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Body-->
      <div class="card-body pt-2">
        <!--begin::LIst Tabs-->

        <v-tabs>
          <v-tab @click="setActiveTab">{{ $t("PRICING.TAB1") }}</v-tab>
          <v-tab @click="setActiveTab">{{ $t("PRICING.TAB3") }}</v-tab>
          <v-tab-item>
            <div class="form-group row">
              <v-card class="w-100">
                <v-card-title>
                  <v-spacer />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('GENERAL.SEARCH')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="itemsActive"
                  :search="search"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <router-link
                      :to="{
                        name: 'pricing-edit',
                        params: { id: item.productPricingTemplateId },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </template>
                  <template v-slot:[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment(campus.date_format) }}
                  </template>
                  <template v-slot:[`item.ruleTypeThresholdDate`]="{ item }">
                    {{
                      item.ruleTypeThresholdDate | moment(campus.date_format)
                    }}
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="form-group row">
              <v-card class="w-100">
                <v-card-title>
                  <v-spacer />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('GENERAL.SEARCH')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="itemsExpired"
                  :search="search"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <router-link
                      :to="{
                        name: 'pricing-edit',
                        params: { id: item.productPricingTemplateId },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </template>
                  <template v-slot:[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment(campus.date_format) }}
                  </template>
                  <template v-slot:[`item.ruleTypeThresholdDate`]="{ item }">
                    {{
                      item.ruleTypeThresholdDate | moment(campus.date_format)
                    }}
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ALL_PRODUCT_PRICINGS } from "@/core/services/store/configure/product_pricing.module";
import Buttons from "@/view/content/components/Buttons";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "pricing",
  components: {
    Buttons,
  },
  data() {
    return {
      save_loading: false,
      tabIndex: 0,
      search: "",
      itemsPerPage: 10,
      page: 1,
      productExpired: [],
      productActive: [],
      headers: [
        {
          text: this.$t("PRICING.TABLE.NAME"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("PRICING.TABLE.EXPIRES_ON"),
          value: "ruleTypeThresholdDate",
          sortable: false,
        },
        {
          text: this.$t("PRICING.TABLE.LAST_UPDATE"),
          value: "updated_at",
          sortable: false,
        },
      ],
      productPricingTemplatesActive: [],
      productPricingTemplatesExpired: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PRICING.PRICING_TEMPLATE") },
    ]);
    this.$store.commit("setIsVisibleLoader", true);
    this.$store.dispatch(GET_ALL_PRODUCT_PRICINGS).then(data => {
      let productPricingTemplates = Vue.util.extend([], data);
      productPricingTemplates = productPricingTemplates.filter(templ => {
        if (templ.campusId === this.campus.campusId) return true;
        return false;
      });

      productPricingTemplates.forEach(item => {
        let item_modified = item;
        let valExpiredDate = this.expiredDate(item.ruleTypeThresholdDate);
        if (item_modified.name === "")
          item_modified.name = this.$t("PRICING.NO_NAME");
        if (valExpiredDate) {
          this.productExpired.push(item);
        } else {
          this.productActive.push(item);
        }
        productPricingTemplates.push(item);
      });
      this.productPricingTemplates = productPricingTemplates;
      this.$store.commit("setIsVisibleLoader", false);

      data;
    });
  },
  methods: {
    expiredDate(date) {
      const DATE_FORMAT = "YYYY-MM-DD";
      const currentDate = this.$moment().format(DATE_FORMAT);
      const expiredDate = this.$moment(date).format(DATE_FORMAT);
      if (date) {
        return this.$moment(currentDate).isAfter(expiredDate);
      }
      return false;
    },
    /**
     * Set previous tab active
     */
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest("[role='tablist']");
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },
    newTemplate() {
      this.$router.push({ name: "pricing-new" });
    },
  },
  computed: {
    ...mapGetters(["campus", "productPricings"]),
    itemsActive() {
      return this.productActive;
    },
    itemsExpired() {
      return this.productExpired;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  > div:nth-child(1) {
    display: none;
  }
}
</style>
